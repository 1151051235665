import React from 'react'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './OnlineConsulting.styles'
import {
  DescriptionWrapper,
  TitleContent,
  TitleWrapper
} from '../Common.styles'
import { Event as GAEvent } from '../../../components/GA'
import { SeoPriceForTitleBlock } from '../../../atoms/SeoPriceForTitleBlock'

export const OnlineConsulting = () => (
  <Wrapper>
    <section>
      <Grid>
        <TitleContent>
          <InfoContainer>
            <TitleWrapper>
              <Title.H1>
                Онлайн консультации
                <br />
                психолога в чате
              </Title.H1>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Вы ищете психологическую помощь, но не можете общаться со
                специалистом по видео?
              </Text.Large>
              <Text.Large semiBold>
                У YouTalk есть подходящее решение — возможность общаться с
                психологом в чате.
              </Text.Large>
              <SeoPriceForTitleBlock />
            </DescriptionWrapper>
            <ButtonsWrapper>
              <Button.NewPrimary
                href="/wizard/"
                onClick={GAEvent.openSemeinyiPageFirstScreen}
                size="large"
                type="link"
              >
                Подобрать психолога
              </Button.NewPrimary>
            </ButtonsWrapper>
          </InfoContainer>
          <ImageWrapper>
            <HeroImage alt="консультации психолога в чате" />
          </ImageWrapper>
        </TitleContent>
      </Grid>
    </section>
  </Wrapper>
)
